<template>
  <div class="user-profile-modal" v-if="showAuctionOverview">
    <v-card class="user-profile-container user-auctions" v-if="auctions && renderComponent">
      <v-card-text class="main-card">
        <v-container>
          <div>
            <v-row align="center" class="form-container justify-center">
              <v-col sm="12" class="full-width" v-if="isAuthenticatedAsUser">
                <h2 class="page-title text-center">{{ $t('Welcome') }}</h2>
                <p class="pt-2 mt-8 mb-4 text-left introduction-text">
                  <span>{{ $t('Here you will find a list of all auctions, including the') }}</span>
                  <strong><span>{{ $t('Product catalogue') }}</span></strong>
                  <span>{{ $t('for searching through the lots and submitting article inquiries') }}</span>
                </p>
                <p class="pt-2 mt-4 mb-4 text-left introduction-text">
                  <span>{{ $t('For submitting absentee bids, open the') }} </span>
                  <span>{{ $t('Auction') }}</span>
                  <span> {{ $t('and click on the') }}</span>
                  "<strong><span>{{ $t('Submit absentee bid') }}</span></strong>"
                  <span>{{ $t('button') }}</span>
                  <span>{{ $t('at the desired lot') }}</span><br>
                  <span>{{ $t('You can also put lots on your') }}</span>
                  <strong><span>{{ $t('wishlist_spaced') }}</span></strong>
                  <span>{{ $t('and submit absentee bids from there') }}</span>
                </p>
                <p class="pt-2 mt-4 mb-4 text-left introduction-text" v-if="appFeatures.liveAuctionFunctionality">
                  <span>{{ $t('To sign up for an auction, click on the') }}</span>
                  "<strong><span>{{ $t('Sign up') }}</span></strong>"
                  <span>{{ $t('button') }}</span>
                  <span>{{ $t('. This is also required if you already placed absentee bids and want to participate in the live auction') }}</span><br>
                  <span>{{ $t('You will be notified via email once your request has been approved by the auction house') }}</span><br>
                  <span>{{ $t('After that, once you login and the auction is running, you can join instantly or alternatively click on the') }}</span>
                  "<strong><span>{{ $t('Join now') }}</span></strong>"
                  <span>{{ $t('button') }}</span><br>
                </p>
                <p class="pt-2 mt-4 mb-4 text-left introduction-text">
                  <span>{{ $t('After the auction, you can view the results or make inquiries for the post auction sale by clicking on the') }}</span>
                  "<strong><span>{{ $t('Show results') }}</span></strong>"
                  <span>{{ $t('button') }}</span><br>
                  <span>{{ $t('For making an inquiry, enable') }}</span>
                  "<strong> <span>{{ $t('Show unsold articles only') }}</span></strong>"
                  <span>{{ $t('and then click on the') }} </span>
                  <v-icon light>fa-shopping-cart</v-icon>
                  <span> {{ $t('icon') }}</span>
                  <span> {{ $t('on the selecte article') }}</span>
                </p>
              </v-col>
              <v-col sm="12" class="full-width" v-else>
                <h2 class="page-title text-center">{{ $t('Welcome') }}</h2>
                <p class="pt-2 mt-4 mb-4 text-left introduction-text">
                  <span>{{ $t('Here you will find an overview of all auctions or sale') }}</span><br>
                </p>
                <v-alert
                  v-if="features.guestLoginPrompt"
                  border="left"
                  dense
                  outlined
                  text
                  type="info"
                  dismissible
                >
                  {{ $t('Please note') }},
                  <span v-if="appFeatures.liveAuctionFunctionality">{{ $t('login_reminder_live') }}</span>
                  <span v-else>{{ $t('login_reminder_no_live') }}</span>
                  <br>
                  <a @click="openRegistration">
                    <strong>{{ $t('Register') }}</strong>
                  </a>
                  / <a @click="openLogin">
                    <strong>{{ $t('Login now') }}</strong>
                  </a>
                </v-alert>
              </v-col>
              <div class="scroll-anchor" ref="anchor" />
              <v-col sm="12" v-if="auctions.length">
                <v-text-field
                  v-model="search"
                  append-icon="fa-magnifying-glass"
                  :label="$t('Search')"
                  hide-details
                  clearable
                  class="mb-3"
                  @input="searchHandler"
                />
                <div class="catalogue list-view">
                  <div class="catalogue__list" :key="overviewKey">
                    <template
                      v-for="auction in auctionsView"
                    >
                      <div
                        :key="auction.id"
                        class="catalogue__item"
                        :class="{ started: auction.status === 'started' || auction.status === 'paused' }"
                        v-if="auction.type !== 'sale'"
                      >
                        <div
                          class="catalogue__image"
                          v-if="auction.banner_image"
                        >
                          <img :src="auction.banner_image">
                        </div>
                        <div class="catalogue__item-content full-width">
                          <div class="catalogue__header">
                            <h3 class="catalogue__name">{{ auctionTitle(auction) }}</h3>
                            <div class="catalogue__heading-buttons">
                              <v-btn
                                @click="openProductCatalogue(auction.code)"
                                class="btn customizable old-styling-btn ml-3"
                                v-if="auction.status !== 'completed' && auction.catalogue_enabled !== 'disabled'"
                              >
                                <v-icon class="mr-2">fa-list</v-icon>
                                <span v-if="auction.type === 'live' || (auction.type === 'timed' && !isAuthenticatedAsUser) || (auction.type === 'timed' && (auction.status === 'preparing' || auction.status === 'stopped'))">{{ $t('Show catalogue') }}</span>
                                <span v-else-if="auction.type === 'timed' && auction.status === 'started' && isAuthenticatedAsUser">{{ $t('Bid now') }}</span>
                              </v-btn>
                              <v-btn
                                @click="openPostAuctionSale(auction.code)"
                                class="btn customizable old-styling-btn ml-3"
                                v-if="(auction.catalogue_enabled === 'enabled_with_results' || auction.catalogue_enabled === 'enabled_with_results_and_estimations') && auction.post_auction_sale_enabled && auction.status === 'completed' && auction.type === 'live'"
                              >
                                <v-icon class="mr-2">fa-shopping-cart</v-icon>
                                {{ $t('Show post auction sale') }}
                              </v-btn>
                              <v-btn
                                @click="openProductCatalogue(auction.code)"
                                class="btn customizable old-styling-btn ml-3"
                                v-if="auction.status === 'completed' && auction.catalogue_enabled !== 'disabled' && auction.catalogue_enabled !== 'enabled'"
                              >
                                <v-icon class="mr-2">fa-poll</v-icon>
                                {{ $t('Show results') }}
                              </v-btn>
                            </div>
                          </div>
                          <div class="catalogue__sub-header text-right">
                            <v-btn class="btn customizable old-styling-btn mb-0" @click="openHighlights(auction.code)">
                              <v-icon class="mr-2">fa-star</v-icon>
                              <span>{{ $t('Show highlights') }}</span>
                            </v-btn>
                          </div>
                          <div class="catalogue__meta">
                            <div class="d-flex flex-wrap">
                              <div class="catalogue__meta-item">
                                <p class="catalogue__meta-name">{{ $t('Type') }}</p>
                                <h5 class="catalogue__meta-value">{{ $t(auction.type) }}</h5>
                              </div>
                              <div class="catalogue__meta-item">
                                <p class="catalogue__meta-name">{{ $t('Lot count') }}</p>
                                <h5 class="catalogue__meta-value">{{ auction.count_articles ? auction.count_articles : '-' }}</h5>
                              </div>
                              <div class="catalogue__meta-item" v-if="auction.estimated_price">
                                <p class="catalogue__meta-name">{{ $t('Estimated price') }}</p>
                                <h5 class="catalogue__meta-value">{{ currency }} {{ auction.estimated_price }}</h5>
                              </div>
                              <div class="catalogue__meta-item" v-if="auction.type === 'timed' && auction.start_at">
                                <p class="catalogue__meta-name">{{ $t('Starts at') }}</p>
                                <div class="catalogue__meta-list">
                                  <h5 class="catalogue__meta-value">
                                    {{ getFormattedDate(auction.start_at, appStoreLang, false, false) }}
                                  </h5>
                                </div>
                              </div>
                              <div class="catalogue__meta-item" v-else-if="auction.sale_dates && auction.sale_dates.dates && auction.sale_dates.dates.length">
                                <p class="catalogue__meta-name">{{ $t('Starts at') }}</p>
                                <div class="catalogue__meta-list">
                                  <h5
                                    class="catalogue__meta-value"
                                    v-for="(date, i) in auction.sale_dates.dates"
                                    :key="i"
                                  >
                                    {{ getFormattedDate(date, appStoreLang, false, false) }}
                                  </h5>
                                </div>
                              </div>
                              <div class="catalogue__meta-item" v-if="auction.type !== 'timed' && auction.preview_data && auction.preview_data.dates && auction.preview_data.dates.length">
                                <p class="catalogue__meta-name">{{ $t('Preview dates') }}</p>
                                <div class="catalogue__meta-list">
                                  <h5
                                    class="catalogue__meta-value"
                                    v-for="(date, i) in auction.preview_data.dates"
                                    :key="i"
                                  >
                                    {{ getFormattedDate(date.starts_at, appStoreLang, false, false) }} - {{ moment(date.ends_at).format('HH:mm') }}
                                  </h5>
                                </div>
                              </div>
                              <div class="catalogue__meta-item" v-if="auction.surcharge">
                                <p class="catalogue__meta-name">{{ $t('Surcharge') }}</p>
                                <h5 class="catalogue__meta-value">{{ auction.surcharge }} %</h5>
                              </div>
                            </div>
                            <div class="d-flex align-center flex-wrap mt-md-0" v-if="isAuthenticatedAsUser">
                              <v-btn
                                @click="UPDATE_GLOBAL_STATE({key: 'showAuctionOverview', value: false})"
                                class="btn customizable old-styling-btn join"
                                color="success"
                                v-if="getStatusClass(auction.id) === 'unlocked' && (auction.status === 'paused' || auction.status === 'started')"
                              >
                                <v-icon class="mr-2">fa-sign-in-alt</v-icon>
                                {{ $t('Join now') }}
                              </v-btn>
                              <div class="d-flex align-center" v-if="auction.type !== 'timed' && (auction.status === 'stopped' || auction.status === 'preparing') && auction.signup_enabled && appFeatures.liveAuctionFunctionality">
                                <h3 class="mr-2" v-if="!isMobile && !!getStatusClass(auction.id)">{{ $t('Status') }}:</h3>
                                <v-btn
                                  @click="signUp(auction)"
                                  class="btn customizable old-styling-btn"
                                  :class="getStatusClass(auction.id)"
                                  :readonly="!!bidderNumbers.find(el => el.auction_id === auction.id && el.type === 'live')"
                                >
                                  <v-icon class="mr-2" v-if="!getStatusClass(auction.id)">fa-plus-circle</v-icon>
                                  <v-icon class="mr-2" v-if="getStatusClass(auction.id) === 'pending'">fa-clock</v-icon>
                                  <v-icon class="mr-2" v-if="getStatusClass(auction.id) === 'locked'">fa-times-circle</v-icon>
                                  <v-icon class="mr-2" v-if="getStatusClass(auction.id) === 'unlocked'">fa-check-circle</v-icon>
                                  {{ getStatusText(auction.id) }}
                                </v-btn>
                              </div>
                              <div class="d-flex align-center" v-if="auction.type !== 'timed' && getStatusClass(auction.id) !== 'unlocked' && (auction.status === 'paused' || auction.status === 'started') && auction.signup_enabled && appFeatures.liveAuctionFunctionality">
                                <h3 class="mr-2" v-if="!isMobile && !!getStatusClass(auction.id)">{{ $t('Status') }}:</h3>
                                <v-btn
                                  @click="signUp(auction)"
                                  class="btn customizable old-styling-btn"
                                  :class="getStatusClass(auction.id)"
                                  :readonly="!!bidderNumbers.find(el => el.auction_id === auction.id && el.type === 'live')"
                                >
                                  <v-icon class="mr-2" v-if="!getStatusClass(auction.id)">fa-plus-circle</v-icon>
                                  <v-icon class="mr-2" v-if="getStatusClass(auction.id) === 'pending'">fa-clock</v-icon>
                                  <v-icon class="mr-2" v-if="getStatusClass(auction.id) === 'locked'">fa-times-circle</v-icon>
                                  {{ getStatusText(auction.id) }}
                                </v-btn>
                              </div>
                            </div>
                          </div>
                          <p class="catalogue__description">{{ auctionDescription(auction) }}</p>
                          <div class="catalogue__footer">
                            <div class="catalogue__footer-item customizable" v-if="auction.terms && auction.terms.length">
                              <a class="catalogue__footer-value" @click="openTermsDialog(auction)"><v-icon class="mr-1" small>fa-info-circle</v-icon>{{ $t('Terms') }}</a>
                            </div>
                            <div class="catalogue__footer-item customizable" v-if="auction.shipping_data && auction.shipping_data.length && !auction.no_shipping">
                              <a class="catalogue__footer-value" @click="openShippingDialog(auction)"><v-icon class="mr-1" small>fa-truck</v-icon>{{ $t('Shipping information') }}</a>
                            </div>
                            <div class="catalogue__footer-item" v-else-if="auction.no_shipping">
                              <v-tooltip top allow-overflow>
                                <template #activator="{ on, attrs }">
                                  <svg
                                    v-bind="attrs"
                                    v-on="on"
                                    height="24px"
                                    width="24px"
                                    fill="#000000"
                                    xmlns="http://www.w3.org/2000/svg"
                                    data-name="Layer 101"
                                    viewBox="0 0 100 100" x="0px" y="0px"
                                    class="catalogue__item-shipping mr-2"
                                  >
                                    <path d="M50,90A40,40,0,1,0,10,50,40,40,0,0,0,50,90Zm0-73.23a33.1,33.1,0,0,1,21,7.46l-4.39,4.39a.63.63,0,0,1,.16.26L69,35.76,75.77,29A33.23,33.23,0,0,1,29,75.77l4.13-4.13h-2.4a.76.76,0,0,1-.75-.75V65.21L24.23,71A33.23,33.23,0,0,1,50,16.77Z" /><path d="M68.5,70.14V39.56H54v8.87a.76.76,0,0,1-.75.75H46.79a.76.76,0,0,1-.75-.75V39.56H31.5V70.14Z" /><polygon points="31.79 38.05 46.08 38.05 46.53 29.86 34.5 29.86 31.79 38.05" /><polygon points="53.92 38.05 68.21 38.05 65.5 29.86 53.47 29.86 53.92 38.05" /><polygon points="52.42 38.05 51.96 29.86 48.04 29.86 47.58 38.05 52.42 38.05" /><rect x="47.54" y="39.56" width="4.92" height="8.13" />
                                  </svg>
                                </template>
                                <span>{{ $t('No shipping possible') }}</span>
                              </v-tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        :key="auction.id"
                        class="catalogue__item"
                        :class="{ started: auction.status === 'started' || auction.status === 'paused' }"
                        v-else-if="auction.type === 'sale' && auction.status === 'started' && auction.count_articles > 0"
                      >
                        <div
                          class="catalogue__image"
                          v-if="auction.banner_image"
                        >
                          <img :src="auction.banner_image">
                        </div>
                        <div class="catalogue__item-content full-width">
                          <div class="catalogue__header">
                            <h3 class="catalogue__name">{{ auctionTitle(auction) }}</h3>
                            <div class="catalogue__heading-buttons">
                              <v-btn
                                @click="openProductCatalogue(auction.code)"
                                class="btn customizable old-styling-btn ml-3"
                                v-if="auction.status !== 'completed' && auction.catalogue_enabled !== 'disabled'"
                              >
                                <v-icon class="mr-2">fa-list</v-icon>
                                <!--<span v-if="auction.commission_bids_enabled == 1">{{ $t('Show catalogue /Place commission bids') }}</span>-->
                                <span>{{ $t('Show sale') }}</span>
                              </v-btn>
                            </div>
                          </div>
                          <div class="catalogue__meta">
                            <div class="d-flex flex-wrap">
                              <div class="catalogue__meta-item">
                                <p class="catalogue__meta-name">{{ $t('Article count') }}</p>
                                <h5 class="catalogue__meta-value">{{ auction.count_articles ? auction.count_articles : '-' }}</h5>
                              </div>
                            </div>
                          </div>
                          <p class="catalogue__description">{{ auctionDescription(auction) }}</p>
                          <div class="catalogue__footer">
                            <div class="catalogue__footer-item" v-if="auction.terms && auction.terms.length">
                              <a class="catalogue__footer-value" @click="openTermsDialog(auction)"><v-icon class="mr-1" small>fa-info-circle</v-icon>{{ $t('Terms') }}</a>
                            </div>
                            <div class="catalogue__footer-item" v-if="auction.shipping_data && auction.shipping_data.length">
                              <a class="catalogue__footer-value" @click="openShippingDialog(auction)"><v-icon class="mr-1" small>fa-truck</v-icon>{{ $t('Shipping information') }}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </v-col>
              <v-col sm="12" class="text-center" v-else>
                <h3>{{ $t('No auctions found') }}</h3>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
    <div class="pagination-container" v-if="totalItems">
      <v-row class="ma-0" justify="center" align="center">
        <v-col class="pa-0">
          <v-row class="ma-0" align="center">
            <v-menu offset-y close-on-content-click>
              <template #activator="{ on, attrs }">
                <v-btn
                  text
                  v-bind="attrs"
                  v-on="on"
                  class="dropdown-btn customizable"
                >
                  <span class="highlight">{{ itemPerPage }}<v-icon class="ml-1">fa-angle-down</v-icon></span>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(item, index) in [25, 50, 75, 100]"
                  :key="index"
                  @click="changePerPage(item)"
                >
                  <v-list-item-title class="text-right">{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <div class="ml-2 mr-2" v-if="!isMobile">
              {{ page * itemPerPage + 1 }}-{{ totalPages > 1 ? totalPages === page + 1 ? totalItems : (page + 1) * itemPerPage : totalItems }} {{ $t('of') }} {{ totalItems }} {{ $t('items') }}
            </div>
          </v-row>
        </v-col>
        <custom-pagination v-if="totalPages > 1" :page="page + 1" @changePage="changePage" :length="totalPages" :totalVisible="10" />
      </v-row>
    </div>

    <user-auction-signup-modal
      :userId="userProfile.id"
      :auctionId="signUpId"
      :require-bid-limit="auctionRequireBidLimit"
      :auction-status="auctionsignUpStatus"
      :auction-start-date="auctionSignUpStartDate"
      ref="auctionSignupModal"
      @refresh="$emit('refreshModal')"
    />

    <v-dialog
      v-model="termsDialog"
      width="500"
      scrollable
      persistent
      @keydown.esc="termsDialog = false"
    >
      <v-card class="py-4">
        <v-btn class="modal-close-btn icon-with-hover" icon @click="termsDialog = false"><v-icon>fa-times</v-icon></v-btn>
        <v-card-title class="justify-center pa-0 mb-4">
          <span class="headline">{{ $t('Terms') }}</span>
        </v-card-title>
        <v-card-text>
          <span v-if="useHtml" class="useHtmlData" v-html="termsDialogData"></span>
          <span v-else>{{ termsDialogData }}</span>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="shippingDialog"
      width="500"
      scrollable
      persistent
      @keydown.esc="shippingDialog = false"
    >
      <v-card class="py-4">
        <v-btn class="modal-close-btn icon-with-hover" icon @click="shippingDialog = false"><v-icon>fa-times</v-icon></v-btn>
        <v-card-title class="justify-center pa-0 mb-4">
          <span class="headline">{{ $t('Shipping information') }}</span>
        </v-card-title>
        <v-card-text>
          <span v-if="useHtml" class="useHtmlData" v-html="shippingDialogData"></span>
          <span v-else>{{ shippingDialogData }}</span>
        </v-card-text>
      </v-card>
    </v-dialog>
    <registration ref="registrationModal" />
  </div>
</template>
<script>
import _ from 'lodash'
import { mapActions, mapState } from 'pinia'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import { AuctionFlavor, AuctionStatusType, localFeatures, timeouts } from '@/utils/constants'
import Registration from '@/components/modals/guest-registration-modal';
import UserAuctionSignupModal from '@/components/modals/user-auction-signup-modal';
import CustomPagination from '@/components/blocks/custom-pagination.vue'
import useRootStore from '@/stores/rootStore'
import useAuctionStore from '@/stores/auctionStore'
import { searchAuctions, sortAuctions } from '@/services/auction';
import { euNumbers, getFormattedDate, getTranslatedText } from '@/services/i18n';

export default {
  name: 'AuctionOverviewModal',
  components: {
    Registration,
    UserAuctionSignupModal,
    CustomPagination
  },
  props: {
    auctions: {
      type: Array,
      default: () => []
    },
    bidderNumbers: {
      type: Array,
      default: () => []
    },
    overviewKey: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    renderComponent: true,
    search: '',
    itemPerPage: 50,
    page: 0,
    start: 0,
    end: 0,
    key: 0,
    error: '',
    tmpAuctions: [],
    loading: false,
    searchResult: [],
    categories: [],
    auctionsView: [],
    modalKey: 0,
    lightBoxIndex: null,
    selectedImages: [],
    dialog: false,
    signUpId: null,
    termsDialog: false,
    shippingDialog: false,
    termsDialogData: null,
    shippingDialogData: null,
    visibleAlert: true,
    features: localFeatures,
    auctionRequireBidLimit: false,
    auctionsignUpStatus: 'none',
    auctionSignUpStartDate: '',
    useHtml: localFeatures.useHtml,
    dashboardNewestAuctionOnTop: localFeatures.dashboardNewestAuctionOnTop
  }),
  computed: {
    ...mapState(useRootStore, ['userProfile', 'appLocalization', 'showAuctionOverview', 'appSettings', 'appStoreLang', 'isMobile', 'appFeatures', 'isAuthenticatedAsUser']),
    ...mapState(useAuctionStore, ['currentAuction']),
    totalPages() {
      return Math.ceil(this.totalItems / this.itemPerPage)
    },
    headers() {
      return [
        {
          value: 'code',
        },
        {
          value: 'name',
        },
        {
          value: 'description',
        }
      ]
    },
    totalItems () {
      let articles = [...this.tmpAuctions]
      return this.search ? this.searchResult.length : articles.length
    },
    currency () {
      return this.appLocalization.currency
    }
  },
  methods: {
    ...mapActions(useRootStore, ['updateUserProfile', 'UPDATE_GLOBAL_STATE']),
    ...mapActions(useAuctionStore, ['CHANGE_STATE']),
    getFormattedDate,
    getFieldName(obj, field) {
      let langTarget
      if (typeof obj[`${field}_i18n`] === 'string' && obj[`${field}_i18n`].length > 0) {
        langTarget = JSON.parse(obj[`${field}_i18n`])
      } else {
        langTarget = obj[`${field}_i18n`]
      }
      if (langTarget && Object.keys(langTarget).length > 0 && langTarget[this.appStoreLang]) {
        return `${field}_i18n`
      }
      return field
    },
    euNumbers,
    changePage (el) {
      this.page = el - 1
      this.$refs.anchor.scrollIntoView({ behavior: 'smooth' })
      this.getAuctionsData()
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    getAuctionsData() {
      let slice = [];
      if (this.search) {
        this.searchResult = []
        const tableSort = [...this.headers]
        let auctions = [...this.tmpAuctions]
        auctions = auctions.filter(e => {
          return !(e.type === AuctionFlavor.live && e.status === AuctionStatusType.completed && !e.post_auction_sale_enabled)
        })

        auctions.forEach(el => {
          tableSort.forEach(val => {
            let str = ''
            if (this.getFieldName(el, val.value).includes('i18n')) {
              str = el[this.getFieldName(el, val.value)][this.appStoreLang.toLowerCase()]
            } else {
              str = el[val.value]
            }
            if (str && str.toString().toLowerCase().includes(this.search.toLowerCase()) && this.searchResult.map(({ id }) => id).indexOf(el.id) === -1) {
              this.searchResult.push(el)
            }
          })
        })
        slice = this.searchResult.slice(this.page * this.itemPerPage, (this.page + 1) * this.itemPerPage)
      } else {
        let auctions = this.tmpAuctions.length ? [...this.tmpAuctions] : [...this.auctions]
        auctions = auctions.filter(e => {
          return !(e.type === AuctionFlavor.live && e.status === AuctionStatusType.completed && !e.post_auction_sale_enabled)
        })
        slice = auctions.slice(this.page * this.itemPerPage, (this.page + 1) * this.itemPerPage)
      }
      this.auctionsView = sortAuctions(slice, this.dashboardNewestAuctionOnTop);
    },
    searchHandler: _.debounce(function () {
      let auctions = [...this.auctions]
      if (this.search) {
        this.tmpAuctions = searchAuctions(this.search, this.auctions, this.appStoreLang)
      } else {
        this.tmpAuctions = auctions
      }
      this.getAuctionsData()
    }, timeouts.debounce),
    auctionTitle (auction) {
      return getTranslatedText(auction, 'name', this.appStoreLang)
    },
    auctionDescription (auction) {
      return getTranslatedText(auction, 'description', this.appStoreLang)
    },
    changePerPage(item) {
      this.itemPerPage = item
      this.page = 0
      this.getAuctionsData()
    },
    selectImage(images, index) {
      this.selectedImages = images.map(({ image }) => image)
      this.lightBoxIndex = index
    },
    openProductCatalogue(id) {
      this.$router.push({ name: 'catalogue', params: { id } })
    },
    openPostAuctionSale(id) {
      this.$router.push({ name: 'postAuctionSale', params: { id } })
    },
    signUp(auction) {
      this.signUpId = auction.id
      this.auctionRequireBidLimit = auction.require_bid_limit_on_signup
      this.auctionsignUpStatus = auction.status
      this.auctionSignUpStartDate = auction.start_at
      if (this.userProfile.profile_complete) {
        this.$refs.auctionSignupModal.openModal()
      } else {
        this.$swal.fire({
          position: 'center',
          icon: 'error',
          title: this.$t('Your profile is incomplete'),
          text: this.$t('Please complete your profile to be able to signup for a live auction or place commission bids'),
          showConfirmButton: false,
          timer: timeouts.closeSweetalert
        })
      }
    },
    getStatusText(id) {
      const target = this.bidderNumbers.find(el => el.auction_id === id && el.type === 'live')
      if (target) {
        if (target.user_status === 'pending') return this.$t('Sign up pending')
        if (target.user_status === 'unlocked') return this.$t('Signed up')
        if (target.user_status === 'locked') return this.$t('Sign up declined')
      }
      return this.$t('Sign up')
    },
    getStatusClass(id) {
      const target = this.bidderNumbers.find(el => el.auction_id === id && el.type === 'live')
      if (target) return target.user_status
    },
    openTermsDialog(auction) {
      this.termsDialog = true
      this.termsDialogData = getTranslatedText(auction, 'terms', this.appStoreLang)
    },
    openShippingDialog(auction) {
      this.shippingDialog = true
      this.shippingDialogData = getTranslatedText(auction, 'shipping_data', this.appStoreLang)
    },
    openHighlights(code) {
      this.$router.push({ name: 'highlights', params: { id: code } })
    },
    openLogin() {
      this.$router.push({ name: 'login', query: { nextUrl: this.$route.fullPath.trim() } })
    },
    openRegistration() {
      this.visibleAlert = false;
      this.$refs.registrationModal.openModal()
    }
  },
  mounted() {
    this.getAuctionsData()
    const target = this.bidderNumbers.filter(el => el.auction_id === this.currentAuction.id)
    const fitRequirement = target.find(el => el.user_status === 'unlocked' && el.type === 'live')
    this.CHANGE_STATE({ key: 'joinModal', value: fitRequirement && (this.currentAuction.status === 'started' || this.currentAuction.status === 'paused') })
  },
  watch: {
    showAuctionOverview() {
      this.$nextTick(() => {
        const target = document.getElementsByClassName('default-parent-zindex')[0]
        target && target.parentNode.classList.add('default-zindex')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.pagination-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
  padding: 25px 80px;
  z-index: 10;
  @media (max-width: 968px) {
    padding: 10px;
  }
}
.catalogue {
  .btn {
    @media (max-width: 968px) {
      width: 230px;
    }
  }
  .v-dialog {
    .dropdown-btn {
      color: #000 !important;
    }
  }
  &__heading-buttons {
    @media (max-width: 600px) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      .btn {
        margin-left: 0 !important;
        margin-right: 10px;
      }
    }
    @media (max-width: 480px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  @media (max-width: 968px) {
    .catalogue__item {
      display: flex;
    }
    .catalogue__image,
    .catalogue__slider {
      margin-right: 25px;
    }
  }
  &.list-view {
    .catalogue__item-wish {
      top: 15px;
      right: 15px;
    }
    .catalogue__item {
      width: 100%;
      max-width: 100%;
      display: flex;
      margin-right: 0;
      margin-left: 0;
    }
    .catalogue__image,
    .catalogue__slider {
      min-width: 20%;
      width: 20%;
      margin-right: 25px;
      @media (max-width: 968px) {
        min-width: 100%;
        width: 100%;
        margin-bottom: 12px;
      }
    }
  }
  &__item {
    border-radius: 0;
    @media (max-width: 968px) {
      flex-wrap: wrap;
    }
    &.started {
      border-width: 2px;
      border-color: green !important;
    }
  }
  &__item-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__meta {
    justify-content: space-between;
    @media (max-width: 968px) {
      flex-direction: column-reverse;
    }
  }
  &__footer {
    position: relative;
    margin-top: 25px;
    display: flex;
    align-items: flex-start;
    top: 10px;
    @media (max-width: 968px) {
      top: 0;
    }
  }
  &__meta-item,
  &__footer-item {
    padding-left: 8px;
    margin-left: 8px;
    border-left: 1px solid #E5E5E5;
    @media (max-width: 968px) {
      display: flex;
      border: none;
      flex-wrap: wrap;
    }
    &:first-child {
      border-left: none;
      padding-left: 0;
      margin-left: 0;
    }
  }
  &__meta-item {
    @media (max-width: 968px) {
      flex: 100%;
      flex-direction: column;
    }
  }
  &__meta-value,
  &__footer-value {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
  &__footer-item,
  &__footer-value {
    cursor: pointer;
    @media (max-width: 968px) {
      font-size: 11px;
    }
  }
  &__meta-list {
    text-align-last: justify;
    @media (max-width: 968px) {
      text-align-last: start;
    }
  }
  &__name {
    margin-bottom: 0;
    @media (max-width: 968px) {
      display: -webkit-box;
      width: 100%;
      margin-bottom: 10px;
    }
  }
  &__description {
    margin: 25px 0 0;
    @media (max-width: 968px) {
      color: #000;
    }
  }
  &__header {
    @media (max-width: 968px) {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0;
    }
  }
  .catalogue__sub-header {
    @media (max-width: 968px) {
      text-align: left !important;
    }
  }
}

.user-profile-container {
  .v-btn {
    @media (max-width: 968px) {
      margin-bottom: 10px;
    }
  }
  .v-btn.v-btn--has-bg {
    &.pending {
      cursor: default;
      pointer-events: none;
      background: rgba(0,0,0,.25)!important;
    }
    &.unlocked {
      cursor: default;
      pointer-events: none;
      background: rgba(0,128,0,.75) !important;
    }
    &.locked {
      cursor: default;
      pointer-events: none;
      background: rgba(139,20,30,.75) !important;
    }
    &:first-child {
      margin-left: 0 !important;
    }
  }
}
.action-buttons {
  .v-btn {
    width: calc(50% - 16px);
    max-width: 150px;
  }
}
.join-title {
  br {
    display: none;
    @media (max-width: 968px) {
      display: block;
    }
  }
}

.introduction-text {
  font-weight: normal;
  font-size: 16px;
}
</style>
