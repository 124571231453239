<template>
  <div class="streaming-wrapper">
    <div class="container" v-if="appSettings.url_stream_type !== 'stream_disabled'">
      <v-row>
        <v-col cols="6">
          <div class="fp-Video" :style="{ width: `${getVideoWidth + 2}px`, height: `${getVideoHeight + 2}px` }">
            <div id="publish" class="display" />
          </div>
        </v-col>
        <v-col cols="6">
          {{ $t('Stats') }}:
          <br>
          <br>
          <template v-if="appSettings.url_stream_type === 'stream_video'">
            {{ $t('Video stats') }}
            <template v-if="!!stream">
              <p class="mb-0">{{ $t('Nack Count') }}: <strong>{{ videoNackCount }}</strong></p>
              <p class="mb-0">{{ $t('Bytes Sent') }}: <strong>{{ videoBytesSent }}</strong></p>
            </template>
            <br>
          </template>
          {{ $t('Audio stats') }}
          <template v-if="!!stream">
            <p class="mb-0">{{ $t('Nack Count') }}: <strong>{{ audioNackCount }}</strong></p>
            <p class="mb-0">{{ $t('Bytes Sent') }}: <strong>{{ audioBytesSent }}</strong></p>
          </template>
        </v-col>
      </v-row>
      <div class="status-indicator my-3">
        Status
        <p class="status" :class="status">{{ getStatusText }}</p>
      </div>
      <div class="d-flex justify-start">
        <v-switch
          color="primary"
          :label="$t('Mute stream')"
          class="mb-4"
          hide-details
          v-model="mute"
          @change="toggleMute"
        />
      </div>
      <v-slider
        max="100"
        min="0"
        v-model="volume"
        :label="$t('Mic gain')"
        style="max-width: 500px;"
        @change="changeVolume"
        thumb-label
      />
      <v-row>
        <v-col cols="6">
          <v-select
            class="select-input "
            hide-details="true"
            v-model="audioSource"
            :items="audioSourceItems"
            append-icon="fa-angle-down"
            :label="$t('Select audio source')"
            :disabled="!!stream"
          />
        </v-col>
        <v-col cols="6">
          <v-select
            class="select-input "
            hide-details="true"
            v-model="videoSource"
            :items="videoSourceItems"
            append-icon="fa-angle-down"
            :label="$t('Select video source')"
            :disabled="!!stream"
            v-if="appSettings.url_stream_type === 'stream_video'"
          />
        </v-col>
      </v-row>
      <div class="buttons-group mt-4">
        <v-btn class="btn primary-btn mr-3" :disabled="!!SESSION_STATUS.DISCONNECTED || !!SESSION_STATUS.FAILED"><v-icon class="mr-2" small>fa-redo</v-icon>{{ $t('Reconnect') }}</v-btn>
        <v-btn class="btn primary-btn mr-3" :disabled="status === 'disconnected' || !SESSION_STATUS.ESTABLISHED || !audioSource || (appSettings.url_stream_type === 'stream_video' && !videoSource)" @click="publishClick" v-if="!stream"><v-icon class="mr-2" small>fa-play</v-icon>{{ $t('Start') }}</v-btn>
        <v-btn class="btn primary-btn mr-3" v-else @click="stopPublish"><v-icon class="mr-2" small>fa-stop</v-icon>{{ $t('Stop') }}</v-btn>
      </div>
    </div>
    <div class="stream_disabled" v-else>{{ $t('Streaming is disabled, please check your settings') }}</div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'pinia'
import Flashphoner from '@flashphoner/websdk'
import * as Sentry from '@sentry/vue';
import { MEDIA_DEVICE_KIND } from '@flashphoner/websdk/src/constants';
import useRootStore from '@/stores/rootStore';
import SocketClient from '@/utils/socket';
import SocketHandlers from '@/api/socketHandlers';

export default {
  name: 'StreamingView',
  components: {
  },
  async created () {
    // Listener
    this.setUpSocket()
  },
  data: function () {
    return {
      mute: false,
      volume: 70,
      audioSource: null,
      videoSource: null,
      audioSourceItems: [{
        text: this.$t('Disabled'),
        value: 'disabled'
      }],
      videoSourceItems: [{
        text: this.$t('Disabled'),
        value: 'disabled'
      }],
      SESSION_STATUS: Flashphoner.constants.SESSION_STATUS,
      STREAM_STATUS: Flashphoner.constants.STREAM_STATUS,
      session: null,
      stream: null,
      PRELOADER_URL: require('@/assets/preloader.mp4'),
      statsIntervalID: null,
      videoNackCount: null,
      videoBytesSent: null,
      audioNackCount: null,
      audioBytesSent: null,
      status: null
    }
  },
  watch: {
  },
  computed: {
    ...mapState(useRootStore, ['appSettings', 'streamSettings']),
    isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    },
    getVideoHeight() {
      return this.streamSettings.videoResolution && +this.streamSettings.videoResolution.slice(0, -1)
    },
    getVideoWidth() {
      const { videoResolution } = this.streamSettings
      //return videoResolution === '240p' ? 360 : 480
      //return videoResolution === '240p' ? 480 : 848
      if (videoResolution === '240p') {
        return 426
      } else if (videoResolution === '360p') {
        return 640
      } else if (videoResolution === '480p') {
        return 848
      } else if (videoResolution === '720p') {
        return 1280
      }

    },
    getStatusText() {
      switch (this.status) {
      case 'disconnected':
        return this.$t('Disconnected')
      case 'stopped':
        return this.$t('Connected - stopped')
      case 'broadcasting':
        return this.$t('Connected - broadcasting')
      case 'error':
        return this.$t('Error')
      default:
        return ''
      }
    }
  },
  mounted() {
    this.SET_ADMIN_DRAWER(false)
    this.initApi()
    this.getMediaDevices()
  },
  methods: {
    ...mapActions(useRootStore, ['SET_TEMP_ALERT', 'SET_ADMIN_DRAWER', 'SET_SOCKET_DATA']),
    initApi() {
      Flashphoner.init({});
      this.session = Flashphoner.createSession({
        urlServer: this.appSettings.url_stream
      }).on(this.SESSION_STATUS.ESTABLISHED, () => {
        this.status = 'stopped'
      }).on(this.SESSION_STATUS.DISCONNECTED, () => {
        this.status = 'disconnected'
      }).on(this.SESSION_STATUS.FAILED, () => {
        this.status = 'disconnected'
      });
    },
    publishClick() {
      if (this.isSafari) {
        Flashphoner.playFirstVideo(document.getElementById('publish'), true, this.PRELOADER_URL).then(() => {
          this.publishStream();
        });
      } else {
        this.publishStream();
      }
    },
    publishStream() {
      let constraints = {
        audio: {
          deviceId: this.audioSource,
          bitrate: this.streamSettings.audioBitrate
        }
      }
      if (this.appSettings.url_stream_type === 'stream_video') {
        constraints = {
          ...constraints,
          video: {
            deviceId: this.videoSource,
            maxBitrate: this.streamSettings.videoBitrateMax,
            minBitrate: this.streamSettings.videoBitrateMin,
            width: this.getVideoWidth,
            height: this.getVideoHeight
          }
        }
      }
      if (this.audioSource === 'disabled') {
        constraints.audio = false
      }
      if (this.videoSource === 'disabled') {
        constraints.video = false
      }
      this.stream = this.session.createStream({
        name: this.appSettings.url_stream_name,
        display: document.getElementById('publish'),
        constraints
      }).on(this.STREAM_STATUS.PUBLISHING, () => {
        this.stream.setMicrophoneGain(this.volume)
        this.statsIntervalID = setInterval(() => {
          this.loadStats()
        }, 1000)
        this.status = 'broadcasting'
      }).on(this.STREAM_STATUS.FAILED, function () {
        this.status = 'error'
        this.stopPublish()
      })
      this.stream.publish();
    },
    stopPublish() {
      this.stream.stop();
      this.status = 'stopped'
      this.stream = null
      if (this.statsIntervalID) {
        clearInterval(this.statsIntervalID);
        this.statsIntervalID = null;
      }
    },
    getMediaDevices() {
      Flashphoner.getMediaDevices(null, true, MEDIA_DEVICE_KIND.INPUT).then(list => {
        list.audio.forEach(device => {
          this.audioSourceItems.push({ text: device.label, value: device.id })
        });
        list.video.forEach(device => {
          this.videoSourceItems.push({ text: device.label, value: device.id })
        });
      }).catch(error => {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('Failed to get media devices') })
        Sentry.captureException(error);
      });
    },
    toggleMute() {
      if (this.stream) {
        if (this.mute) {
          this.stream.muteAudio()
          this.stream.muteVideo()
        } else {
          this.stream.unmuteAudio()
          this.stream.unmuteVideo()
        }
      }
    },
    changeVolume() {
      if (this.stream) {
        this.stream.setMicrophoneGain(this.volume)
      }
    },
    reconnect() {
      this.session = null
      this.stream = null
      this.initApi()
      this.publishClick()
    },
    loadStats() {
      this.stream.getStats(stats => {
        if (stats && stats.outboundStream) {
          if (stats.outboundStream.video) {
            this.videoNackCount = stats.outboundStream.video.nackCount
            this.videoBytesSent = stats.outboundStream.video.bytesSent
          }
          if (stats.outboundStream.audio) {
            this.audioNackCount = stats.outboundStream.audio.nackCount
            this.audioBytesSent = stats.outboundStream.audio.bytesSent
          }
        }
      })
    },
    setUpSocket() {
      const { socket } = SocketClient.getInstance();
      const emitter = SocketHandlers.getEmitter();

      this.SET_SOCKET_DATA(socket)
    },
  },
}
</script>
<style scoped lang="scss">
.container {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}
.fp-Video {
  border: 1px double black;
}
.display {
  width: 100%;
  height: 100%;
  display: inline-block;
}
.display > video,
object {
  width: 100%;
  height: 100%;
}
.btn {
  &.v-btn--disabled.v-btn {
    .v-icon {
      color: #fff !important;
    }
  }
}
.status {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 0;
  &.disconnected,
  &.error {
    color: red;
  }
  &.stopped {
    color: #000;
  }
  &.broadcasting {
    color: green;
  }
}
.stream_disabled {
  width: 400px;
  max-width: 100%;
  margin: 50px auto 0;
  text-align: center;
  border-radius: 30px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  background: red;
  color: #fff;
  padding: 15px;
}
</style>
